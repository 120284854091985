
:root {
  --color-primary: #E7720A;  
  --color-hover-orange: rgba(231, 114, 10, 0.42);
  --color-pricing: rgba(231, 114, 10, 0.9);
  --color-purple-3: #7208B7;
  --color-white: #fff;
  --color-dark-black:#171716;
  --color-nav-black:#040301;
  --color-text-grey:#707070;
  --color-glasscard-bg:#FCFCFC;
  --color-banner-text:rgba(248, 248, 248, 0.9);
  --color-features-bg:#F8D2B1;
  --color-background: #FFF4E2; 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
}

section {

  overflow-y: visible;
}

.pricing-section,
.features-section,
.home-section,
.constraints-section {
  background-color: var(--color-background);
  position: relative;
  overflow: hidden;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing-section,
.features-section,
.home-section,
.constraints-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.home-section {
  padding-top: calc(5rem + 70px); 
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FFF4E2;
  color: var(--color-nav-black);
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}



.constraints-container{
  padding-left: 2rem; padding-right: 2rem;
}


.pricing-section h2,
.features-section h2,
.constraints-heading {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  font-size: 54px;
  color: var(--color-dark-black);
  margin-bottom: 3rem;
  font-style: italic;
  text-align: center;
}

.features-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.constraints-content {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .pricing-section,
  .features-section,
  .constraints-container {
    padding: 4rem 1rem;
  }

  .pricing-section h2,
  .features-section h2,
  .constraints-heading {
    font-size: 36px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .pricing-section,
  .features-section,
  .constraints-container {
    padding: 3rem 1rem;
  }

  .pricing-section h2,
  .features-section h2,
  .constraints-heading {
    font-size: 28px;
    margin-bottom: 1.5rem;
  }
}
